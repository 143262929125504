import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ResponsiveAppBar from './Components/ResponsiveAppBar';
import Footer from './Components/Footer';
import CircularProgress from '@mui/material/CircularProgress';

// Lazy load all components
const HomePageBanner = React.lazy(() => import('./Components/HomePageBanner'));
const AboutUs = React.lazy(() => import('./Components/AboutUs'));
const OurServices = React.lazy(() => import('./Components/OurServices'));
const ContactUs = React.lazy(() => import('./Components/ContactUs'));
const Testimonials = React.lazy(() => import('./Components/Testimonials'));

const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress 
      color="secondary" 
      size={80} 
      thickness={4} 
      variant="determinate" 
      value={70} 
    />
    {/* <p>Loading content...</p> */}
  </div>
);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ResponsiveAppBar />
    <React.Suspense fallback={<Loader />}>
      <div id="home">
        <HomePageBanner />
      </div>
      <div id="about">
        <AboutUs />
      </div>
      <div id="services">
        <OurServices />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <div id="contact">
        <ContactUs />
      </div>
    </React.Suspense>
    <Footer />
  </React.StrictMode>
);
