import React from "react";
import { Box, Typography, Link, Grid, IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#2b098f",
        padding: "2rem",
        marginTop: "2rem",
        borderTop: "1px solid #ddd",
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={4} textAlign="center">
          <Box
            sx={{
              marginBottom: "1rem",
              height: { xs: 45, sm: 45 },
              width: { xs: 250, sm: 250 },
            }}
          >
            <img
              src="./logo-white-no-bg.png"
              alt="Global Web Labs Logo"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
          <Typography variant="body2" color="#fff">
            We are a leading company specializing in web and mobile solutions.
            Our mission is to provide high-quality services and innovative
            solutions to our clients.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography variant="h6" gutterBottom color="#fff">
            Quick Links
          </Typography>
          <Typography variant="body2" color="#fff">
            <Link href="#home" color="inherit" underline="none">
              Home
            </Link>
            <br />
            <Link href="#about" color="inherit" underline="none">
              About Us
            </Link>
            <br />
            <Link href="#services" color="inherit" underline="none">
              Our Services
            </Link>
            <br />
            <Link href="#contact" color="inherit" underline="none">
              Contact Us
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography variant="h6" gutterBottom color="#fff">
            Contact Info
          </Typography>
          <Typography variant="body2" color="#fff">
            Address: Phase7, Mohali, India
            <br />
            Email: hr@globalweblabs.com
          </Typography>
          <Box sx={{ marginTop: "1rem" }}>
            <IconButton
              component="a"
              href="mailto:hr@globalweblabs.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Email"
              sx={{ color: "#0077b5", "&:hover": { color: "#fff" } }}
            >
              <MailIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.instagram.com/globalweblabs#"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
              sx={{ color: "#e4405f", "&:hover": { color: "#fff" } }}
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.linkedin.com/company/globalweblabs/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
              sx={{ color: "#0077b5", "&:hover": { color: "#fff" } }}
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box textAlign="center" marginTop="2rem">
        <Typography variant="body2" color="#fff">
          © {new Date().getFullYear()} Global Web Labs. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
